<template>
    <!-- 订单路由 -->
    <div class="order_router_box">
        <div class="container">
            <!-- 面包屑导航 -->
            <bread-crumb :breadcrumb="breadcrumb"></bread-crumb>
            <!-- 步骤条 -->
            <div class="order_steps_box">
                <el-steps 
                    :active="activeSteps">
                    <el-step 
                        :title="item" 
                        v-for="item in stepsData"
                        :key="item.id">
                    </el-step>
                </el-steps>
            </div>
            <!-- 标题部分 -->
            <div class="order_info_title_box"
                v-if="$route.path.indexOf('cancel')<0">
                <!-- 标题 -->
                <div class="order_info_title">订单信息</div>
                <!-- 订单信息 -->
                <div class="order_info_content">
                    <!-- 订单号 -->
                    <span>订单号：803201661</span>
                    <!-- 创建时间 -->
                    <span class="order_info_content_createtime">创建时间：2018-11-10 16:06:54</span>
                </div>
            </div>
            <!-- 路由部分 -->
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 面包屑导航参数
            breadcrumb: [],
            // 步骤条参数
            stepsData: [],
            // 步骤条当前步骤
            activeSteps: 2,
        }
    },
    components: {
        // 面包屑导航组件
        breadCrumb: () => import('@/components/breadCrumb'),
    },
    methods: {
        judge(p, h){
            // 上级路由位置 => 面包屑导航
            if (h.indexOf("lease") >= 0) {
                this.breadcrumb = [
                    {
                        path: "/center/lease",
                        path_name: "租赁订单",
                    },
                    {
                        path: "/order/lease",
                        path_name: "详情",
                    },
                ];
            } else if (p.indexOf("photography") >= 0) {
                this.breadcrumb = [
                    {
                        path: "/center/photography",
                        path_name: "摄影订单",
                    },
                    {
                        path: "/order/photography",
                        path_name: "详情",
                    },
                ];
            } else if (p.indexOf("site") >= 0) {
                this.breadcrumb = [
                    {
                        path: "/center/site",
                        path_name: "场地订单",
                    },
                    {
                        path: "/order/site",
                        path_name: "详情",
                    },
                ];
            };

            // 当前路由位置 => 步骤条
            if (p.indexOf("lease") >= 0){ // 租赁订单
                this.stepsData = [
                    "等待确认",
                    "支付",
                    "租赁中",
                    "评价",
                ];
            } else if (p.indexOf("photography") >= 0) { // 摄影订单
                this.stepsData = [
                    "核对订单信息",
                    "支付",
                    "等待服务",
                    "样片上传",
                    "完成",
                ];
            } else if (p.indexOf("site") >= 0) { // 场地订单
                this.stepsData = [
                    "核对订单信息",
                    "支付",
                    "等待使用",
                    "完成",
                ];
            } else if (p.indexOf("cancel") >= 0) {
                this.stepsData = [
                    "申请取消",
                    "等待处理",
                    "退款完毕",
                ];
            };
        },
    },
    mounted() {
        
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.judge(to.path, from.path);
        })
    },
}
</script>

<style scoped>
/* 订单路由盒子 */
.order_router_box{
    margin: 50px 0;
}

/* 订单步骤条盒子 */
.order_steps_box{
    margin: 30px 0;
}

/* 订单标题 */
.order_info_title{
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #303133;
}
/* 创建时间 */
.order_info_content_createtime{
    float: right;
}
/* 订单标题盒子 */
.order_info_title_box{
    margin-bottom: 20px;
}
</style>

